.color {
  &-primary {
    color: var(--primary-color);
  }

  &-text {
    &-primary {
      color: var(--text-color-primary);
    }

    &-secondary {
      color: var(--text-color-secondary);
    }

    &-error {
      color: var(--input-error-color);
    }
  }

  &-warn {
    color: var(--color-warn);
  }
}
