/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';

// IMPORTANT: we create a sub-layer 'reset' under 'primeng' so that such resets will be applied correctly
// without breaking our current customizations of PrimeNg components
@layer primeng.reset {
  @import 'reset';
}

@layer custom {
  @import 'primeng-divider';
}

@import 'alignments';
@import 'colors';
@import 'flex';
@import 'fonts';
@import 'grid';
@import 'miscellaneous';
@import 'positions';
@import 'sizes';
@import 'spacings';
@import 'text';
@import 'tooltip';

html {
  font-size: 14px; // This is necessary because primeNg components have 14px as the base font size
}

body {
  margin: 0;
  cursor: default;
  background-color: var(--surface-ground);
}

a {
  color: var(--primary-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.user-menu {
  .p-overlaypanel::after,
  .p-overlaypanel::before {
    border: none;
  }

  .p-button {
    background: transparent;
    border: none;
  }
}

.p-overlaypanel {
  &::after,
  &::before {
    border: none;
  }

  .p-button {
    background: transparent;
    border: none;
    padding-inline-start: 0;
    color: var(--surface-900);

    &:hover,
    &:focus {
      border: none;
    }

    &-label {
      font-weight: normal;
    }

    &-label:hover {
      text-decoration: underline;
      border: none;
    }
  }

  .link {
    color: var(--surface-900);
  }
}
