@import 'beta-ui/src/assets/styles/functions/unit-transform';

.tippy-box {
  background: var(--surface-border);
  color: var(--text-color);
  box-shadow:
    0 rem(2px) rem(4px) rem(-1px) rgba(0, 0, 0, 0.2),
    0 rem(4px) rem(5px) 0 rgba(0, 0, 0, 0.14),
    0 rem(1px) rem(10px) 0 rgba(0, 0, 0, 0.12);
  border-radius: rem(6px);
  max-width: rem(200px);
}

.tippy-variation-tooltip {
  .tippy-box {
    padding: rem(12px) rem(12px);
  }

  padding: rem(12px);
  z-index: 100000000 !important;
}
