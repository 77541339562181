.inline-flex {
  display: inline-flex;
}

.flex {
  display: flex;

  &-1 {
    flex: 1;
  }
}

.flex-col {
  flex-flow: column;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-basis {
  &-33 {
    flex-basis: 33%;
  }

  &-64 {
    flex-basis: 64%;
  }

  &-100 {
    flex-basis: 100%;
  }
}

.wrap {
  flex-wrap: wrap;
}
