/*
IMPORTANT: some font-faces in this file are commented on purpose so that their corresponding font file won't be downloaded by the browser.
In that case, when there is a font-style without a matching font-face, the browser will simulate it using the most similar font-face available.
This is to reduce the amount of font files downloaded by the browser, only those that are strictly needed will be downloaded.
*/
@font-face {
  font-family: 'Source Sans 3';
  src: url('../../assets/fonts/SourceSans3-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  src: url('../../assets/fonts/SourceSans3-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans 3';
  src: url('../../assets/fonts/SourceSans3-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
