@import 'beta-ui/src/assets/styles/functions/unit-transform';

.h {
  &-full {
    height: 100%;
  }

  &-dvh {
    height: 100dvh;
  }
}

.min-w {
  &-120 {
    min-width: rem(120px);
  }

  &-160 {
    min-width: rem(160px);
  }

  &-180 {
    min-width: rem(180px);
  }

  &-200 {
    min-width: rem(200px);
  }

  &-240 {
    min-width: rem(240px);
  }

  &-340 {
    min-width: rem(340px);
  }
}

.size {
  &-12 {
    width: rem(12px);
    height: rem(12px);
  }

  &-14 {
    width: rem(14px);
    height: rem(14px);
  }

  &-18 {
    width: rem(18px);
    height: rem(18px);
  }

  &-20 {
    width: rem(20px);
    height: rem(20px);
  }

  &-24 {
    width: rem(24px);
    height: rem(24px);
  }

  &-28 {
    width: rem(28px);
    height: rem(28px);
  }

  &-32 {
    width: rem(32px);
    height: rem(32px);
  }

  &-38 {
    width: rem(38px);
    height: rem(38px);
  }
}

.w {
  &-full {
    width: 100%;
  }
}

.max-w {
  &-880 {
    max-width: rem(880px);
  }

  &-1512 {
    max-width: rem(1512px);
  }
}
