@import 'beta-ui/src/assets/styles/functions/unit-transform';

.font {
  &-bold {
    font-weight: 700;
  }

  &-semibold {
    font-weight: 600;
  }

  &-14 {
    font-size: rem(14px);
  }

  &-16 {
    font-size: rem(16px);
  }

  &-18 {
    font-size: rem(18px);
  }

  &-20 {
    font-size: rem(20px);
  }

  &-21 {
    font-size: rem(21px);
  }

  &-22 {
    font-size: rem(22px);
  }

  &-24 {
    font-size: rem(24px);
  }

  &-28 {
    font-size: rem(28px);
  }

  &-35 {
    font-size: rem(35px);
  }

  &-40 {
    font-size: rem(40px);
  }

  &-48 {
    font-size: rem(48px);
  }
}

.leading {
  &-tight {
    line-height: 1.2;
  }

  &-normal {
    line-height: 1.5;
  }
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}
