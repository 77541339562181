@import 'beta-ui/src/assets/styles/functions/unit-transform';

.items {
  &-baseline {
    align-items: baseline;
  }

  &-start {
    align-items: flex-start;
  }

  &-center {
    align-items: center;
  }

  &-end {
    align-items: flex-end;
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-between {
    justify-content: space-between;
  }

  &-end {
    justify-content: flex-end;
  }
}

.text {
  &-center {
    text-align: center;
  }
}
