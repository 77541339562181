@import 'beta-ui/src/assets/styles/functions/unit-transform';

.cursor-pointer {
  cursor: pointer;
}

.rounded {
  &-md {
    border-radius: rem(6px);
  }

  &-xl {
    border-radius: rem(12px);
  }

  &-full {
    border-radius: 9999px;
  }
}

.opacity {
  &-60 {
    opacity: 0.6;
  }

  &-100 {
    opacity: 1;
  }
}

.overflow {
  &-y {
    &-auto {
      overflow-y: auto;
    }
  }
}
