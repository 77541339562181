@import 'beta-ui/src/assets/styles/functions/unit-transform';

.gap {
  &-0 {
    gap: rem(0px);
  }

  &-4 {
    gap: rem(4px);
  }

  &-6 {
    gap: rem(6px);
  }

  &-8 {
    gap: rem(8px);
  }

  &-10 {
    gap: rem(10px);
  }

  &-12 {
    gap: rem(12px);
  }

  &-14 {
    gap: rem(14px);
  }

  &-16 {
    gap: rem(16px);
  }

  &-20 {
    gap: rem(20px);
  }

  &-22 {
    gap: rem(22px);
  }

  &-24 {
    gap: rem(24px);
  }

  &-28 {
    gap: rem(28px);
  }

  &-38 {
    gap: rem(38px);
  }

  &-45 {
    gap: rem(45px);
  }

  &-56 {
    gap: rem(56px);
  }

  &-100 {
    gap: rem(100px);
  }
}

.m {
  &x {
    &-auto {
      margin-inline: auto;
    }

    &-14 {
      margin-inline: rem(14px);
    }
  }

  &y {
    &-21 {
      margin-block: rem(21px);
    }

    &-28 {
      margin-block: rem(28px);
    }

    &-38 {
      margin-block: rem(38px);
    }
  }

  &b {
    &-28 {
      margin-block-end: rem(28px);
    }
  }

  &t {
    &-auto {
      margin-block-start: auto;
    }

    &-28 {
      margin-block-start: rem(28px);
    }
  }
}

.p {
  &-28 {
    padding: rem(28px);
  }

  &e {
    &-8 {
      padding-inline-end: rem(8px);
    }

    &-18 {
      padding-inline-end: rem(18px);
    }
  }

  &s {
    &-4 {
      padding-inline-start: rem(4px);
    }
  }

  &t {
    &-8 {
      padding-block-start: rem(8px);
    }
  }

  &x {
    &-7 {
      padding-inline: rem(7px);
    }

    &-12 {
      padding-inline: rem(12px);
    }

    &-14 {
      padding-inline: rem(14px);
    }

    &-18 {
      padding-inline: rem(18px);
    }

    &-21 {
      padding-inline: rem(21px);
    }

    &-28 {
      padding-inline: rem(28px);
    }
  }

  &y {
    &-4 {
      padding-block: rem(4px);
    }

    &-7 {
      padding-block: rem(7px);
    }

    &-10 {
      padding-block: rem(10px);
    }

    &-14 {
      padding-block: rem(14px);
    }

    &-21 {
      padding-block: rem(21px);
    }

    &-28 {
      padding-block: rem(28px);
    }

    &-38 {
      padding-block: rem(38px);
    }

    &-70 {
      padding-block: rem(70px);
    }
  }
}
